import { parseISO } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
const { utcToZonedTime, format } = require('date-fns-tz');

export function formatPrice(price) {
    const locale = getLocale();
    const currencyCode = (locale == 'en-US' ? 'USD' : 'BRL');

    return Number(price).toLocaleString(locale, { style: 'currency', currency: currencyCode });
}

export function formatDate(date, showTime = true, customFormat = null) {
    if (!date) {
        return '';
    }

    const locale = getLocale();

    const newdate = parseISO(date);

    const timeZone = getTimeZone(locale);

    const dateLocale = getDateLocale(locale);

    const zonedTime = utcToZonedTime(newdate, timeZone);

    let dateformat = customFormat || 'P';

    if (showTime && !customFormat) {
        dateformat += 'p';
    }

    return format(zonedTime, dateformat, { locale: dateLocale })
}

function getDateLocale(locale) {
    switch (locale) {
        case 'pt-BR':
            return ptBR;
        case 'en-US':
            return enUS;
        default:
            return ptBR;
    }
}

function getTimeZone(locale) {
    switch (locale) {
        case 'pt-BR':
            return 'America/Sao_Paulo';
        case 'en-US':
            return 'America/Guayaquil';
        default:
            return 'America/Sao_Paulo'
    }
}

function insertZeroBefore(number) {
    return ('0' + number).slice(-2)
}

export function formatAddress(address) {
    const locale = getLocale();

    switch (locale) {
        case 'pt-BR':
            return `${address.addressStreet || ''}, ${address.addressNumber || ''}, ${address.addressZipcode || ''} ${address.addressCity || ''}-${address.addressRegion || ''}`;
        case 'en-US':
            return `${address.addressNumber || ''} ${address.addressStreet || ''}, ${address.addressCity || ''} ${address.addressRegion || ''} ${address.addressZipcode || ''}`;
        default:
            return null;
    }
}

export function getLocale() {
    return localStorage.getItem('i18nextLng');
}

export const generateInstallments = (totalAmount, maxQuantity) => {

    const installments = [];

    Array(maxQuantity).fill('').forEach((installment, quantity) => {

        if (quantity > maxQuantity) {
            return;
        }

        const currentInstallment = parseFloat(totalAmount) / (parseInt(quantity) + 1);

        installments.push({
            value: currentInstallment.toFixed(2),
            quantity: parseInt(quantity) + 1
        });

    });

    return installments;

}

export function captalize(string) {

    const stringArray = string.split(' ');

    for (let i = 0; i < stringArray.length; i++) {
        stringArray[i] = stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1);
    }

    return stringArray.join(" ");
}

export function calcWorkingDays(fromDate, days) {
    var count = 0;
    while (count < days) {
        fromDate.setDate(fromDate.getDate() + 1);
        if (fromDate.getDay() != 0 && fromDate.getDay() != 6) // Skip weekends
            count++;
    }
    return fromDate;
}

export function isDarkColor(hex) {
    let r, g, b;

    let nib = hex.split('');

    r = parseInt(nib[1] + nib[2], 16);
    g = parseInt(nib[3] + nib[4], 16);
    b = parseInt(nib[5] + nib[6], 16);

    const lum = (r * 299 + g * 587 + b * 114) / 1000;

    return lum < 128;
}

export function getCartBundlePrice(item, items) {
    let total = item.price;

    items.map(child => {
        if (child.parent && child.type != 'addon' && child.parent == item.id)
            total = Number(total) + Number(child.price);
    });

    return total;
};

export function getDefaultProductPrices(product) {
    const { type, price, finalPrice, bundleGroup = [] } = product;

    let totalItems = 0;

    if (type == 'bundle' && bundleGroup.length > 0) {
        bundleGroup.map((item) => {
            item.options.map((option) => {
                if (option.defaultOption && option.defaultOption === '1') {
                    totalItems += Number(option.product.finalPrice);
                }
            });
        });
    }

    return {
        price: Number(price) + totalItems,
        finalPrice: Number(finalPrice) + totalItems
    };
}

export function getProductValues(product, discountPercentage) {
    let { finalPrice, price } = getDefaultProductPrices(product);

    let installmentTax = 1.230352;
    let installmentValue = Number(finalPrice * 1) / 12;
    let installmentValueWithInterest = Number(finalPrice * installmentTax) / 21;

    let discountPrice = finalPrice;
    if (product.priceSimulation?.inCash) {
        let discount = Math.round((1 - (product.priceSimulation.inCash.finalPrice / product.finalPrice)) * 100);
        if (discount > 0) {
            discountPrice = finalPrice * (1 - (discount / 100));
        }
    }

    return {
        price,
        discountPrice,
        installmentValue: formatPrice(installmentValue),
        installmentValueWithInterest: formatPrice(installmentValueWithInterest),
        formattedPrice: formatPrice(price),
        formattedDiscountPrice: formatPrice(discountPrice),
    }
}

export const getInstalmentRule = (price) => {
    let maxInstalmentQuantity;
    let instalmentQuantity;
    let instalmentPrice;
    let taxes = []
    let minimumInstalmentPrice = 5;

    taxes = [
        1,
        1,
        1.0451,
        1.0604,
        1.0759,
        1.0915,
        1.1072,
        1.1231,
        1.1392,
        1.1554,
        1.1717,
        1.1882,
        1.2048
    ];

    maxInstalmentQuantity = 12;

    instalmentQuantity = Math.floor(price / minimumInstalmentPrice);

    if (instalmentQuantity > maxInstalmentQuantity) {
        instalmentQuantity = maxInstalmentQuantity;
    }

    price = (price * taxes[instalmentQuantity - 1]);

    instalmentPrice = (price / instalmentQuantity);

    return {
        quantity: instalmentQuantity,
        price: instalmentPrice
    }
}

export const generateInstallmentsWithInterest = (totalAmount, feesInstallments) => {
    const installments = [];
    const maxInstalmentQuantity = 21;
    const minimumInstalmentPrice = 5;
    const taxes = [
        1, // 1
        1, // 2
        1, // 3
        1, // 4
        1, // 5
        1, // 6
        1, // 7
        1, // 8
        1, // 9
        1, // 10
        1, // 11
        1, // 12
        1.092197, // 13
        1.108580, // 14
        1.125209, // 15
        1.142087, // 16
        1.159218, // 17
        1.176607, // 18
        1.194256, // 19
        1.212170, // 20
        1.230352, // 21
    ];
    let instalmentQuantity;
    instalmentQuantity = Math.floor(totalAmount / minimumInstalmentPrice);

    if (instalmentQuantity > maxInstalmentQuantity) {
        instalmentQuantity = maxInstalmentQuantity;
    }

    Array(instalmentQuantity).fill('').forEach((installment, quantity) => {

        const number = parseInt(quantity) + 1;

        if (number > instalmentQuantity) {
            return;
        }

        let amount = parseFloat(totalAmount);
        let taxes = 0;
        if (feesInstallments[number - 1] && feesInstallments[number - 1].value && feesInstallments[number - 1].value != 'null') {
            taxes = feesInstallments[number - 1].value / 100;
        }

        amount = amount + (amount * taxes);

        const currentInstallment = parseFloat(amount) / (parseInt(number));

        installments.push({
            value: currentInstallment.toFixed(2),
            quantity: number,
            total: amount
        });

    });

    return installments;

}

function biggerProductionTime(item, items) {
    let productionTime = item.productionTime ? item.productionTime : 0;
    items.map(child => {
        if (child.parent == item.id && child.productionTime > productionTime)
            productionTime = child.productionTime;
    });
    return productionTime;
}

export const getTotalProductionTime = (order) => {
    let productionTime = 0;
    order.items.map(item => {
        if (item.parent) {
            return;
        }
        const bigger = biggerProductionTime(item, order.items);
        if (bigger && bigger > productionTime) {
            productionTime = bigger;
        }
    });
    return productionTime;
}

export function getCartItemLimitAlert(items) {
    const errors = [];

    items.map(item => {

        if (!item.parent && item.quantity > 3 &&
            item.attributeGroup.id == '627edfcf22dfe7354'
        ) {

            errors.push({
                id: item.productId,
                message: "Limite de 03 unidades para o produto " + item.name + " por compra. ",
                name: item.name,
                price: item.price,
                quantity: item.quantity,
                sku: item.sku
            });

        }

    });

    return errors;
};

export function storeCurrency() {
    //não implementado, retornando valor default:
    return {
        code: 'BRL',
        symbol: 'R$'
    }
    // const language = getStoreLanguage();

    // switch(language) {
    //   case 'pt-BR':
    //     return {
    //       code: 'BRL',
    //       symbol: 'R$'
    //     }
    //   case 'en-US':
    //     return {
    //       code: 'USD',
    //       symbol: '$'
    //     }
    //   default:
    //     return {
    //       code: 'BRL',
    //       symbol: 'R$'
    //     }
    // }
}

export function getDiscountValue(totalValue, discountType, discountValue) {
    let totalDiscount = 0;
    let totalWithDiscount = 0;

    switch (discountType) {
        case 'percent':
        case 'percentual':
            totalDiscount = Number(totalValue) / 100 * Number(discountValue);
            totalWithDiscount = Number(totalValue) - totalDiscount;
            break;
        case 'amount':
            totalDiscount = Number(discountValue);
            totalWithDiscount = Number(totalValue) - totalDiscount;
            break;
        default:
            totalDiscount = 0;
            totalWithDiscount = Number(totalValue);
            break;
    }

    return {
        totalDiscount,
        totalWithDiscount
    }
}

export function getPixOffer(salePriceOffers, paymentOptions) {
    const activeOffers = salePriceOffers.filter(offer => Number(offer.value) > 0);

    const pixOffer = activeOffers.find(offer => {
        const paymentOption = paymentOptions[offer.payment] || {};

        return paymentOption.type === 'pix';
    })

    if (!pixOffer) {
        return false;
    }

    return {
        ...pixOffer,
        discount: pixOffer.discountType === 'percentual' ? `${pixOffer.value}%` : formatPrice(pixOffer.value)
    }
}

export function getCartItemPrice(item, items) {
    let total = Number(item.price) * Number(item.quantity);

    if ((item.type == 'bundle')) {
        items.map(child => {
            if (item.id == child.parent && child.type != 'addon') {
                total += (Number(child.price) * Number(child.quantity));
            }
        });
    }

    return total;
}

export function getCartItemUnitPrice(item, items) {
    let total = Number(item.price) * Number(item.quantity);

    if ((item.type == 'bundle')) {
        items.map(child => {
            if (item.id == child.parent && child.type != 'addon') {
                total += (Number(child.price) * Number(child.quantity));
            }
        });
    }

    return total / Number(item.quantity);
}

export function getCardBrand(number) {
    const numberFormated = number.replace(/\D/g, '')

    var patterns = {
        VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MASTER: /^5[1-5][0-9]{14}$/,
        AMEX: /^3[47][0-9]{13}$/,
        ELO: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        AURA: /^(5078\d{2})(\d{2})(\d{11})$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
        DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        DISCOVERY: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        HIPERCARD: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
        ELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        MAESTRO: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        DANKORT: /^(5019)\d+$/,
        INTERPAYMENT: /^(636)\d+$/,
        UNIONPAY: /^(62|88)\d+$/,
    }

    for (var key in patterns) {
        if (patterns[key].test(numberFormated)) {
            return key
        }
    }
}

export function getAddonPrice(addon, productFinalPrice, quantity) {
    let price = 0;
    if (addon && addon.price) {
        price = addon.price;
        if (addon.priceType === 'percent') {
            const percent = addon.price;
            price = productFinalPrice * (percent / 100);
        } else if (addon.priceType === 'price_range') {
            if (addon.rates && addon.rates.length) {
                const ratePrice = addon.rates.find(rate => {
                    if ((rate.startPrice && rate.startPrice <= productFinalPrice) && (rate.endPrice && rate.endPrice >= productFinalPrice)) {
                        return rate;
                    } else if ((rate.startPrice && rate.startPrice <= productFinalPrice) && !rate.endPrice) {
                        return rate;
                    }
                    return;
                })
                if (ratePrice) {
                    price = ratePrice.price;
                }
            }
        }
    }
    return price * quantity;
}
