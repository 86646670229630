import ManagerApi from 'src/services/managerApi';
import { addMessage, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import { v4 as uuidv4 } from 'uuid';
import { trackCartAction } from 'src/utils/analytics';
import { getCartItemUnitPrice } from 'src/utils/functions';

export const FETCH_CART = 'FETCH_CART';
export const UPDATED_CART = 'UPDATED_CART';
export const CLEAR_CART = 'CLEAR_CART';

export function addProductCart(payload) {
    return async (dispatch, getState) => {
        const { data, quantity, isNvidia, bundle, parent, parentByProductId } = payload;
        const { cart, account } = getState();

        const requestId = uuidv4();
        dispatch(addLoadingGlobal(requestId));

        try {
            let apiUrl = '/sale/order';
            if (cart.order.id) {
                apiUrl = apiUrl + '/' + cart.order.id;
            }

            const saleOrderApi = new ManagerApi(apiUrl);

            let formData = new FormData();

            if (Array.isArray(data)) {
                data.forEach((product, index) => {
                    if (product.delete) {
                        formData.append(`items[${index}][id]`, product.product);
                        formData.append(`items[${index}][delete]`, product.delete);
                    } else {
                        formData.append(`items[${index}][product]`, product.product.id);
                    }

                    formData.append(`items[${index}][quantity]`, product.quantity);

                    if (product.parent) {
                        formData.append(`items[${index}][parent]`, product.parent);
                    }

                    if (product.parentByProductId) {
                        const cartItem = cart.order.items.find(cartItem => cartItem.productId === product.parentByProductId);
                        if (cartItem) {
                            formData.append(`items[${index}][parent]`, cartItem.id)
                        }
                    }

                    if (product.isNvidia) {
                        formData.append(`items[${index}][additionalInformation][nvidia]`, product.isNvidia);
                    }

                    if (product.bundle) {
                        if (product.bundle.template) {
                            formData.append(`items[${index}][additionalInformation][bundle][template][id]`, product.bundle.template.id);
                            formData.append(`items[${index}][additionalInformation][bundle][template][name]`, product.bundle.template.name);
                        } else if (product.bundle.groups) {
                            for (let key in product.bundle.groups) {
                                formData.append(`items[${index}][additionalInformation][bundle][groups][${key}][id]`, product.bundle.groups[key].id);
                                formData.append(`items[${index}][additionalInformation][bundle][groups][${key}][name]`, product.bundle.groups[key].name);
                                if (product.bundle.groups[key].products.length) {
                                    for (let keyProduct in product.bundle.groups[key].products) {
                                        formData.append(
                                            `items[${index}][additionalInformation][bundle][groups][${key}][products][${keyProduct}][productId]`,
                                            product.bundle.groups[key].products[keyProduct].productId
                                        );
                                        formData.append(
                                            `items[${index}][additionalInformation][bundle][groups][${key}][products][${keyProduct}][name]`,
                                            product.bundle.groups[key].products[keyProduct].name
                                        );
                                        formData.append(
                                            `items[${index}][additionalInformation][bundle][groups][${key}][products][${keyProduct}][quantity]`,
                                            product.bundle.groups[key].products[keyProduct].quantity
                                        );
                                    }
                                }
                            }
                        }
                    }
                });
            } else {
                formData.append('items[0][product]', data.id);
                formData.append('items[0][quantity]', quantity);

                if (parent) {
                    formData.append('items[0][parent]', parent);
                }

                if (parentByProductId) {
                    const cartItem = cart.order.items.find(cartItem => cartItem.productId === parentByProductId);
                    if (cartItem) {
                        formData.append('items[0][parent]', cartItem.id)
                    }
                }

                if (isNvidia) {
                    formData.append('items[0][additionalInformation][nvidia]', isNvidia);
                }

                if (bundle) {
                    if (bundle.template) {
                        formData.append('items[0][additionalInformation][bundle][template][id]', bundle.template.id);
                        formData.append('items[0][additionalInformation][bundle][template][name]', bundle.template.name);
                    } else if (bundle.groups) {
                        for (let key in bundle.groups) {
                            formData.append('items[0][additionalInformation][bundle][groups][' + key + '][id]', bundle.groups[key].id);
                            formData.append('items[0][additionalInformation][bundle][groups][' + key + '][name]', bundle.groups[key].name);
                            if (bundle.groups[key].products.length) {
                                for (let keyProduct in bundle.groups[key].products) {
                                    formData.append(
                                        'items[0][additionalInformation][bundle][groups][' + key + '][products][' + keyProduct + '][productId]',
                                        bundle.groups[key].products[keyProduct].productId
                                    );
                                    formData.append(
                                        'items[0][additionalInformation][bundle][groups][' + key + '][products][' + keyProduct + '][name]',
                                        bundle.groups[key].products[keyProduct].name
                                    );
                                    formData.append(
                                        'items[0][additionalInformation][bundle][groups][' + key + '][products][' + keyProduct + '][quantity]',
                                        bundle.groups[key].products[keyProduct].quantity
                                    );
                                }
                            }
                        }
                    }
                }
            }

            const response = await saleOrderApi.post(formData);

            if (response.data.success) {
                localStorage.setItem('orderId', response.data.content.id);

                const orderError = getOrderError(response.data.content.errors);

                if (orderError) {
                    dispatch(addMessage(orderError.message, 'warning'));
                }

                if (!orderError) { // || orderError?.id !== productId
                    dispatch(addMessage('Produto adicionado no seu carrinho', 'success'));
                }

                dispatch({
                    type: UPDATED_CART,
                    payload: response.data.content
                });

                if (Array.isArray(data)) {
                    data.forEach((product, index) => {
                        if (!product.delete) {
                            trackCartAction({
                                action: 'add_to_cart',
                                product: product.product,
                                quantity,
                                customer: account?.user,
                                order: response.data.content
                            });
                        }
                    });
                } else {
                    trackCartAction({
                        action: 'add_to_cart',
                        product: data,
                        quantity,
                        customer: account?.user,
                        order: response.data.content
                    });
                }

                return true;
            } else {
                dispatch(fetchCart(cart.order.id));
                return false;
            }
        } catch (error) {
            return error;
        } finally {
            dispatch(removeLoadingGlobal(requestId));
        }
    };
};

export function fetchCart(orderId) {
    return async (dispatch) => {

        const requestId = uuidv4();
        dispatch(addLoadingGlobal(requestId));

        try {
            if (orderId) {
                localStorage.setItem('orderId', orderId);

                const saleOrderApi = new ManagerApi('/sale/order');
                const response = await saleOrderApi.get(orderId);

                if (response.data.success && response.data.content && response.data.content.id) {
                    dispatch({
                        type: UPDATED_CART,
                        payload: response.data.content
                    })
                }
                else {
                    dispatch(addMessage('O seu carrinho expirou.', 'error'))
                    localStorage.removeItem('orderId');
                    dispatch({
                        type: CLEAR_CART,
                    })
                }
            }
            else {
                localStorage.removeItem('orderId');
                dispatch({
                    type: CLEAR_CART,
                })
            }
        } catch (error) {
            dispatch(addMessage('O seu carrinho expirou.', 'error'))
            localStorage.removeItem('orderId');
            dispatch({
                type: CLEAR_CART,
            })
        }

        dispatch(removeLoadingGlobal(requestId));

    };
};

export function updateCart(order) {
    return (dispatch) => {
        if (order.id) {
            dispatch({
                type: UPDATED_CART,
                payload: order
            });
        }
    };
};

export function deleteItemCart(item) {
    const itemId = item.id;

    return async (dispatch, getState) => {

        const { cart, account } = getState();

        try {
            const requestId = uuidv4();

            dispatch(addLoadingGlobal(requestId));

            const saleOrderApi = new ManagerApi('/sale/order/' + cart.order.id);

            let data = {
                items: [{
                    id: itemId,
                    delete: 1
                }]
            };

            const response = await saleOrderApi.post(data);

            dispatch(removeLoadingGlobal(requestId));

            if (response.data.success) {
                dispatch(addMessage('Produto removido do seu carrinho', 'success'));

                const product = cart.order.items.find(cartItem => cartItem.id === itemId);

                dispatch({
                    type: UPDATED_CART,
                    payload: response.data.content
                });

                trackCartAction({
                    action: 'remove_from_cart',
                    product: {
                        ...product,
                        price: getCartItemUnitPrice(product, cart.order.items)
                    },
                    quantity: product.quantity,
                    customer: account?.user,
                    order: response.data.content
                });
            }
            else {
                dispatch(fetchCart(cart.order.id));
            }

            return true;
        }
        catch (e) {
            return e;
        }
    };
};

export function updateItemCart(item, itemQuantity, bundle, addons = []) {
    const itemId = item.id;

    return async (dispatch, getState) => {
        const { cart, account } = getState();

        try {
            const requestId = uuidv4();

            dispatch(addLoadingGlobal(requestId));

            const saleOrderApi = new ManagerApi('/sale/order/' + cart.order.id);

            let data = {
                items: [{
                    id: itemId,
                    quantity: itemQuantity,
                    bundle: bundle,
                    addons: addons
                }]
            };

            const response = await saleOrderApi.post(data);

            dispatch(removeLoadingGlobal(requestId));

            const responseErrors = response.data.content.errors;
            let productStockError = null;
            if (Object.keys(responseErrors).includes('productStock')) {
                productStockError = responseErrors.productStock[0];
            }

            if (response.data.success) {
                const product = cart.order.items.find(item => item.id === itemId);

                const orderError = getOrderError(response.data.content.errors);

                if (orderError) {
                    dispatch(addMessage(orderError.message, 'warning'));
                }

                // if (itemQuantity > 3) {
                //   dispatch(addMessage('Limite de 03 unidades de cada produto por compra', 'warning'));
                // }

                if (!orderError || orderError?.id !== product.productId) {
                    dispatch(addMessage('Produto atualizado no seu carrinho', 'success'));
                }

                const previousQuantity = product.quantity;

                const quantityChange = Math.abs(Number(itemQuantity) - Number(previousQuantity));

                const cartAction = itemQuantity > previousQuantity ? 'add_to_cart' : 'remove_from_cart';

                dispatch({
                    type: UPDATED_CART,
                    payload: response.data.content
                });

                trackCartAction({
                    action: cartAction,
                    product: {
                        ...product,
                        price: getCartItemUnitPrice(product, cart.order.items)
                    },
                    quantity: quantityChange,
                    customer: account?.user,
                    order: response.data.content
                });
            }
            else {
                dispatch(fetchCart(cart.order.id));
            }

            return true;
        }
        catch (e) {
            return e;
        }
    };
};

function getOrderError(errors = {}) {
    if (Object.keys(errors).includes('productStock')) {
        return errors.productStock[0];
    }
    return false;
}

export const clearCart = (id) => (dispatch) => dispatch({
    type: CLEAR_CART,
    id: id
});
